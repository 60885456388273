import { readonly, ref, computed } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useApi from '~/composables/useApi';
import getAmOrderListGql from '~/modules/GraphQL/customQueries/getAmOrderList.gql';
import getAmDefaultSortingGql from '~/modules/GraphQL/customQueries/getAmDefaultSorting.gql';
import { useAmastySortingStore } from '~/modules/catalog/category/stores/amastySorting';
import { getSortingValue, hydrateSortingOptions, definePageType } from './helpers';

export function useAmastySorting(): any {
  const amastySortingStore = useAmastySortingStore();
  const { query } = useApi();
  const loading = ref(false);
  const error = ref(null);

  const fetchDefaultSorting = async (isSearch: boolean = false) => {
    if (amastySortingStore.default && amastySortingStore.prevIsSearch === isSearch) return;

    loading.value = true;
    try {
      const defautSortingParams = {
        pageType: definePageType(isSearch),
      };

      const defautSortingRes = await query<{ defaultSorting: any }>(getAmDefaultSortingGql, defautSortingParams);
      const defautSortingValue = getSortingValue(defautSortingRes?.data?.defaultSorting?.[0]) || '';

      amastySortingStore.$patch((state) => {
        state.default = defautSortingValue;
        state.prevIsSearch = isSearch;
      });
    } catch (err) {
      error.value = err;
      Logger.error('useAmasty/fetchDefaultSorting', err);
    }
    loading.value = false;
  };

  const fetchSortingOptions = async () => {
    if (amastySortingStore.available.length > 0) return;

    loading.value = true;
    try {
      const { data } = await query<{ amOrderList: any[] }>(getAmOrderListGql);

      const hydratedOptions = hydrateSortingOptions(data.amOrderList);

      amastySortingStore.$patch((state) => {
        state.available = hydratedOptions;
      });
    } catch (err) {
      error.value = err;
      Logger.error('useAmasty/fetchSortingOptions', err);
    }
    loading.value = false;
  };

  const result = computed(() => ({
    default: amastySortingStore.default,
    available: amastySortingStore.available,
  }));

  return {
    fetchDefaultSorting,
    fetchSortingOptions,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export default useAmastySorting;
