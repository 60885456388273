import { defineStore } from 'pinia';
import { CategoryTree } from '~/composables';
import categoryListGql from '~/modules/GraphQL/customQueries/categoryListWithChildrens.gql';
// import categoryListLandingGql from '~/modules/GraphQL/customQueries/categoryListWithChildrensLanding.gql';
// import categoryListWithOneChildrenGql from '~/modules/GraphQL/customQueries/categoryListWithOneChildren.gql';
import { Logger } from '~/helpers/logger';

interface CategoryState {
  rawCategories: CategoryTree[] | null;
  isBasicTreeLoading: boolean;
  activeCategoryFullData: {
    url: string | null;
    data: CategoryTree | null;
  };
  // activeMainCategoryFullData: {
  //   url: string | null;
  //   data: CategoryTree | null;
  // };
  mainCategoryFullData: CategoryTree | null;
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
    isBasicTreeLoading: false,
    activeCategoryFullData: {
      url: null,
      data: null,
    },
    // activeMainCategoryFullData: {
    //   url: null,
    //   data: null,
    // },
    mainCategoryFullData: null,
  }),
  actions: {
    // async load(params: UseCategoryParamsInput, parentUid: string, isLanding = false) {
    //   Logger.debug('useCategoryStore/load', params);
    //   const { filters, pageSize } = params;
    //   const { data }: { data: { categoryList?: CategoryTree[] } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({
    //     query: isLanding ? categoryListLandingGql : categoryListGql,
    //     queryVariables: {
    //       filters,
    //       pageSize,
    //     },
    //   });
    //   if (!this.rawCategories || this.rawCategories.length === 0) {
    //     return;
    //   }
    //   const rawCategoriesChildrensExtended = this.rawCategories[0]?.children.map((category) => {
    //     const categoryCopy = { ...category };
    //     if (category.uid === parentUid) {
    //       categoryCopy.children = data?.categoryList;
    //     }
    //     return categoryCopy;
    //   });
    //   const rawCategoriesCopy = [...this.rawCategories];
    //   this.rawCategories = null;
    //   this.rawCategories = [{ ...rawCategoriesCopy[0], children: rawCategoriesChildrensExtended }];
    // },
    async loadBasicTree({ root }) {
      this.isBasicTreeLoading = true;
      Logger.debug('useCategoryStore/load');

      const { data: rootCategoryData }: { data: { categoryList?: CategoryTree[] } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({
        query: categoryListGql,
        queryVariables: root.query,
      });
      if (!rootCategoryData?.categoryList || rootCategoryData?.categoryList.length === 0) {
        return;
      }
      this.rawCategories = rootCategoryData?.categoryList;
      this.isBasicTreeLoading = false;
    },
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories;
    },
    isLoading(state) {
      return state.isBasicTreeLoading;
    },
  },
});
