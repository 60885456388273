import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0de215db = () => interopDefault(import('../modules/storyblok/pages/adresy-sklepow/AddressIndex.vue' /* webpackChunkName: "" */))
const _c8b2fdce = () => interopDefault(import('../modules/storyblok/pages/LandingPages.vue' /* webpackChunkName: "" */))
const _2cb75568 = () => interopDefault(import('../pages/Authentication.vue' /* webpackChunkName: "" */))
const _413d6de2 = () => interopDefault(import('../modules/storyblok/pages/blog/BlogIndex.vue' /* webpackChunkName: "" */))
const _02086810 = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "" */))
const _7f94dfc6 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _6a6b9402 = () => interopDefault(import('../modules/checkout/pages/Checkout/ShippingAndPayment.vue' /* webpackChunkName: "" */))
const _635ba414 = () => interopDefault(import('../modules/checkout/pages/Checkout/Summary.vue' /* webpackChunkName: "" */))
const _7807e2d7 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _cbab887a = () => interopDefault(import('../modules/checkout/pages/Checkout/Upselling.vue' /* webpackChunkName: "" */))
const _5dd78f50 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _844c1e00 = () => interopDefault(import('../modules/customer/pages/CreatePassword/CreatePassword.vue' /* webpackChunkName: "" */))
const _12d123b8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _0b8f6992 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _2f26306c = () => interopDefault(import('../modules/customer/pages/MyAccount/MergingAccounts/MergingAccounts.vue' /* webpackChunkName: "" */))
const _58f604e4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _e76c2c44 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _5264dc5c = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _3ab32ea0 = () => interopDefault(import('../modules/customer/pages/MyAccount/GDPRAccount/GDPRAccount.vue' /* webpackChunkName: "" */))
const _7200f366 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _b33a7844 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _35fac8bc = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1fe62c3e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _36ac2b62 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _a0293002 = () => interopDefault(import('../modules/storyblok/pages/kariera/KarieraIndex.vue' /* webpackChunkName: "" */))
const _1a570b4d = () => interopDefault(import('../modules/storyblok/pages/kontakt/KontaktIndex.vue' /* webpackChunkName: "" */))
const _34deffff = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _37917f4a = () => interopDefault(import('../modules/storyblok/pages/PrognozaPogody.vue' /* webpackChunkName: "" */))
const _75f17cf6 = () => interopDefault(import('../modules/storyblok/pages/PrzedstawicieleHandlowi.vue' /* webpackChunkName: "" */))
const _0bdf9016 = () => interopDefault(import('../modules/storyblok/pages/adresy-sklepow/AddressPage.vue' /* webpackChunkName: "" */))
const _4e59b33c = () => interopDefault(import('../modules/storyblok/pages/blog/BlogPage.vue' /* webpackChunkName: "" */))
const _b699bd58 = () => interopDefault(import('../modules/storyblok/pages/i/StaticPages.vue' /* webpackChunkName: "" */))
const _1fdd2d1c = () => interopDefault(import('../modules/storyblok/pages/kariera/KarieraPage.vue' /* webpackChunkName: "" */))
const _671d2464 = () => interopDefault(import('../modules/storyblok/pages/kontakt/KontaktPage.vue' /* webpackChunkName: "" */))
const _4d4f994a = () => interopDefault(import('../modules/storyblok/pages/p/StaticPages.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adresy-sklepow",
    component: _0de215db,
    name: "addressPageIndex___agrosimex_pl"
  }, {
    path: "/agrosimex-biznes-klub",
    component: _c8b2fdce,
    name: "agrosimexBiznesKlubPageIndex___agrosimex_pl"
  }, {
    path: "/agrosimex-premium-klub",
    component: _c8b2fdce,
    name: "agrosimexPremiumKlubPageIndex___agrosimex_pl"
  }, {
    path: "/amiata-sredniowczesna-soja-o-wysokim-potencjale-plonowania",
    component: _c8b2fdce,
    name: "amiataSredniowczesnaSojaoWysokimPotencjalePlonowaniaPageIndex___agrosimex_pl"
  }, {
    path: "/authentication",
    component: _2cb75568,
    name: "authentication___agrosimex_pl"
  }, {
    path: "/Authentication",
    component: _2cb75568,
    name: "Authentication___agrosimex_pl"
  }, {
    path: "/blog",
    component: _413d6de2,
    name: "blogPageIndex___agrosimex_pl"
  }, {
    path: "/cart",
    component: _02086810,
    name: "cart___agrosimex_pl"
  }, {
    path: "/Cart",
    component: _02086810,
    name: "Cart___agrosimex_pl"
  }, {
    path: "/checkout",
    component: _7f94dfc6,
    name: "checkout___agrosimex_pl",
    children: [{
      path: "shipping-and-payment",
      component: _6a6b9402,
      name: "shipping-and-payment___agrosimex_pl"
    }, {
      path: "summary",
      component: _635ba414,
      name: "summary___agrosimex_pl"
    }, {
      path: "thank-you",
      component: _7807e2d7,
      name: "thank-you___agrosimex_pl"
    }, {
      path: "upselling",
      component: _cbab887a,
      name: "upselling___agrosimex_pl"
    }, {
      path: "user-account",
      component: _5dd78f50,
      name: "user-account___agrosimex_pl"
    }]
  }, {
    path: "/create-password",
    component: _844c1e00,
    name: "create-password___agrosimex_pl"
  }, {
    path: "/customer",
    component: _12d123b8,
    meta: {"titleLabel":"Moje konto"},
    name: "customer___agrosimex_pl",
    children: [{
      path: "addresses-details",
      component: _0b8f6992,
      meta: {"titleLabel":"Moje adresy"},
      name: "customer-addresses-details___agrosimex_pl"
    }, {
      path: "merging-accounts",
      component: _2f26306c,
      meta: {"titleLabel":"Łączenie kont"},
      name: "customer-merging-accounts___agrosimex_pl"
    }, {
      path: "my-profile",
      component: _58f604e4,
      meta: {"titleLabel":"Moje dane"},
      name: "customer-my-profile___agrosimex_pl"
    }, {
      path: "my-wishlist",
      component: _e76c2c44,
      meta: {"titleLabel":"Ulubione"},
      name: "customer-my-wishlist___agrosimex_pl"
    }, {
      path: "order-history",
      component: _5264dc5c,
      meta: {"titleLabel":"Historia zakupów"},
      name: "customer-order-history___agrosimex_pl"
    }, {
      path: "remove-account",
      component: _3ab32ea0,
      meta: {"titleLabel":"Usuwanie konta"},
      name: "customer-remove-account___agrosimex_pl"
    }, {
      path: "/reset-password",
      component: _7200f366,
      alias: "/customer/account/createPassword",
      name: "reset-password___agrosimex_pl"
    }, {
      path: "addresses-details/create",
      component: _b33a7844,
      meta: {"titleLabel":"Moje adresy"},
      name: "customer-addresses-details-new___agrosimex_pl"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35fac8bc,
      meta: {"titleLabel":"Moje adresy"},
      props: true,
      name: "customer-addresses-details-edit___agrosimex_pl"
    }, {
      path: "order-history/:orderId",
      component: _1fe62c3e,
      meta: {"titleLabel":"Historia zakupów"},
      props: true,
      name: "customer-single-order___agrosimex_pl"
    }]
  }, {
    path: "/Home",
    component: _36ac2b62,
    name: "Home___agrosimex_pl"
  }, {
    path: "/kariera",
    component: _a0293002,
    name: "karieraPageIndex___agrosimex_pl"
  }, {
    path: "/konkurs-lapa-szczescia",
    component: _c8b2fdce,
    name: "konkursLapaSzczesciaIndexRoute___agrosimex_pl"
  }, {
    path: "/konkurs-mikolajkowy-agrosimex",
    component: _c8b2fdce,
    name: "konkursMikolajkowyAgrosimexPageIndex___agrosimex_pl"
  }, {
    path: "/konkurs-wiosenny-agrosimex",
    component: _c8b2fdce,
    name: "konkursWiosennyAgrosimexPageIndex___agrosimex_pl"
  }, {
    path: "/kontakt",
    component: _1a570b4d,
    name: "kontaktPageIndex___agrosimex_pl"
  }, {
    path: "/Page",
    component: _34deffff,
    name: "Page___agrosimex_pl"
  }, {
    path: "/prognoza-pogody",
    component: _37917f4a,
    name: "prognozaPogodyIndexRoute___agrosimex_pl"
  }, {
    path: "/programy-lojalnosciowe",
    component: _c8b2fdce,
    name: "programyLojalnosciowePageIndex___agrosimex_pl"
  }, {
    path: "/promocja-akcesoria-sadownicze",
    component: _c8b2fdce,
    name: "promocjaAkcesoriaSadowniczeIndexRoute___agrosimex_pl"
  }, {
    path: "/przedstawiciele-handlowi",
    component: _75f17cf6,
    name: "przedstawicieleHandlowiPageIndex___agrosimex_pl"
  }, {
    path: "/zainwestuj-w-przyszlosc-twojego-pola-poznaj-najlepsze-odmiany-kukurydzy-od-agrosimex",
    component: _c8b2fdce,
    name: "zainwestujwPrzyszloscTwojegoPageIndex___agrosimex_pl"
  }, {
    path: "/adresy-sklepow/:slug+",
    component: _0bdf9016,
    name: "addressPages___agrosimex_pl"
  }, {
    path: "/blog/:slug+",
    component: _4e59b33c,
    name: "blogPages___agrosimex_pl"
  }, {
    path: "/i/:slug+",
    component: _b699bd58,
    name: "staticPagesI___agrosimex_pl"
  }, {
    path: "/kariera/:slug+",
    component: _1fdd2d1c,
    name: "karieraPages___agrosimex_pl"
  }, {
    path: "/kontakt/:slug+",
    component: _671d2464,
    name: "kontaktPages___agrosimex_pl"
  }, {
    path: "/p/:slug+",
    component: _4d4f994a,
    name: "staticPagesP___agrosimex_pl"
  }, {
    path: "/",
    component: _36ac2b62,
    name: "home___agrosimex_pl"
  }, {
    path: "/:slug+",
    component: _34deffff,
    name: "page___agrosimex_pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
