const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    include_in_menu
    id
    is_anchor
    name
    image
    mobile_banner_image
    category_banner_link
    category_popular_sku
    product_count
    children_count
    uid
    url_path
    url_suffix
    thumbnail_image
    thumbnail_image_2
    megamenu_image_1
    megamenu_image_2
    megamenu_image_1_link
    megamenu_image_2_link
    level
    position
  }
`;

export default `
  query categoryList($filters: CategoryFilterInput, $pageSize: Int = 20) {
    categoryList(filters: $filters, pageSize: $pageSize) {
      ...CategoryFields
      children {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
            children {
              ...CategoryFields
            }
          }
        }
      }
    }
  }
  ${fragmentCategory}
`;
