import {
  computed, useContext, useRoute, watchEffect,
} from '@nuxtjs/composition-api';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { findActiveCategory } from '~/modules/catalog/category/helpers/findActiveCategory';
import { findCategoryAncestors } from '~/modules/catalog/category/helpers/findCategoryAncestors';

/**
 * Logic for finding the current product category and its parent and grandparent categories (ancestors)
 */
export function useTraverseCategory() {
  const context = useContext();
  const categoryStore = useCategoryStore();
  const route = useRoute();

  const getCategoryQueryParams = (uid) => ({
    filters: { category_uid: { eq: uid } },
    pageSize: 9999,
  });
  // const getChildCategoryQueryParams = (uid) => ({
  //   filters: { parent_category_uid: { eq: uid } },
  //   pageSize: 9999,
  // });

  const categoryTree = computed(() => categoryStore.rawCategories);
  const isCategoryTreeLoaded = computed(() => categoryStore.isLoading || categoryStore.categories !== null);

  // const loadCategoryTree = (uid: string) => uid && categoryStore.load(getChildCategoryQueryParams(uid), uid);
  // const loadCategoryTreeLanding = (uid: string) => uid && categoryStore.load(getChildCategoryQueryParams(uid), uid, true);
  const loadBasicCategoryTree = async (rootUid) => categoryStore.loadBasicTree({
    root: {
      query: getCategoryQueryParams(rootUid),
      uid: rootUid,
    },
  });

  const initCategoryTree = async () => {
    await loadBasicCategoryTree(
      context.env.VSF_MAGENTO_ROOT_CATEGORY_UID as string,
    );
  };

  const activeCategory = computed(() => categoryStore.activeCategoryFullData?.data || null);
  // const activeMainCategory = computed(() => categoryStore.activeMainCategoryFullData?.data || null);

  const mainCategory = computed(() => {
    if (!categoryStore.rawCategories) return null;
    if (!categoryStore.mainCategoryFullData) {
      categoryStore.mainCategoryFullData = findActiveCategory(
        categoryStore.rawCategories,
        context.env.VSF_MAGENTO_MAIN_CATEGORY_UID as string,
        'uid',
      );
    }
    return categoryStore.mainCategoryFullData;
  });

  // watchEffect(() => {
  //   if (!categoryStore.rawCategories) return;
  //   const urlPathToFind = route.value.path
  //     .replace(context.app.localePath('/'), '')
  //     .replace(/^\//, '')
  //     .replace('.html', '')
  //     .split('/')[0];
  //   if ((categoryStore.activeMainCategoryFullData?.url !== urlPathToFind) || !categoryStore.activeMainCategoryFullData?.data) {
  //     categoryStore.activeMainCategoryFullData = {
  //       url: urlPathToFind,
  //       data: findActiveCategory(categoryStore.rawCategories, urlPathToFind) || null,
  //     };
  //   }
  // });

  watchEffect(() => {
    if (!categoryStore.rawCategories) return;
    const urlPathToFind = route.value.path
      .replace(context.app.localePath('/'), '')
      .replace(/^\//, '')
      .replace('.html', '');
    if ((categoryStore.activeCategoryFullData?.url !== urlPathToFind)) {
      categoryStore.activeCategoryFullData = {
        url: urlPathToFind,
        data: findActiveCategory(categoryStore.rawCategories, urlPathToFind) || null,
      };
    }
  });

  const categoryAncestors = computed(() => (activeCategory.value === null
    ? []
    : findCategoryAncestors(categoryStore.rawCategories[0], activeCategory.value)) ?? []);

  return {
    mainCategory,
    activeCategory,
    // activeMainCategory,
    categoryAncestors,
    categoryTree,
    // loadCategoryTree,
    // loadCategoryTreeLanding,
    initCategoryTree,
    isCategoryTreeLoaded,
  };
}
