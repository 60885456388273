export default `
  #graphql
  fragment OptimizedVariantFields on ProductInterface {
    order_number
    product_withdrawn
    estimated_avalibility
    id
    sku
    manufacturer
    uid
    name
    url_key
    ean
  }
`;
