import { defineStore } from 'pinia';

interface AmastySortingState {
  default: string;
  available: { label: string; value: string }[];
  prevIsSearch: boolean;
}

export const useAmastySortingStore = defineStore('amastySorting', {
  state: (): AmastySortingState => ({
    default: '',
    available: [],
    prevIsSearch: false,
  }),
});
